import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function CustomRadioComponent(props) {
    return (
        <FormControl>
            <FormLabel style={{ color: 'black', fontWeight: 'bold', fontFamily: 'Kanit' }}>{props.title ?? ""}</FormLabel>
            <RadioGroup
                row
                style={{ fontFamily: 'Kanit' }}
                value={props.value}
                onChange={props.onRadioChange}
            >
                {
                    props.options?.map( value =>
                        <FormControlLabel style={{ fontFamily: 'Kanit' }} value={value} control={<Radio />} label={getLabel(value)} />
                    )
                }
            </RadioGroup>
        </FormControl>
    );

    function getLabel(str) {
        if (str && (str?.length ?? 0) > 0) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        }

        return "-"
    }
}